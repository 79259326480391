<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            defaultStart="-3M"
            defaultEnd="0d"
            label="기간"
            name="years"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발급부서 -->
          <c-dept type="search" label="신청부서" name="issuedDeptCd" v-model="searchParam.issuedDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 단위공정 -->
          <c-process
            label="LBL0000222"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SWP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="swpStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.swpStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 작업허가서 년도별 통계 -->
    <c-table
      title="작업허가서 설비별 통계"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
          <!-- @click.stop="linkClick(props.row, col)"> -->
        <q-btn
          v-if="props.row[col.name]"
          class="tableinnerBtn"
          :class="{'tableinnerBtn-left': col.align === 'left' ? true : false,  'tableinnerBtn-right': col.align === 'right' ? true : false}"
          flat
          :align="col.align"
          color="blue-6">
          <template v-slot>
            {{props.row[col.name] | toThousandFilter}}
          </template>
        </q-btn>
        <template v-else>0</template>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit-state-002',
  data() {
    return {
      searchParam: {
        plantCd: null,
        years: [],
        issuedDeptCd: null,
        processCd: null,
        swpStepCd: null,
      },
      searchedParam: {
        plantCd: null,
        years: [],
        issuedDeptCd: null,
        processCd: null,
        swpStepCd: null,
      },
      grid: {
        columns: [
          {
            name: 'workYear',
            field: 'workYear',
            // 공정
            label: '설비',
            align: 'left',
            sortable: true,
          },
          {
            name: 'fire',
            field: 'fire',
            // 화기
            label: 'LBL0000219',
            align: 'right',
            sortable: true,
            type: 'custom',
            style: 'width:8%',
          },
          {
            name: 'normal',
            field: 'normal',
            // 일반
            label: 'LBL0000008',
            align: 'right',
            sortable: true,
            type: 'custom',
            style: 'width:8%',
          },
          {
            name: 'sub',
            field: 'sub',
            label: 'LBL0000205',
            child: [
              {
                name: 'confinedSpace',
                field: 'confinedSpace',
                // 밀폐
                label: 'LBL0000017',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'blackout',
                field: 'blackout',
                // 정전
                label: 'LBL0000279',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'radiation',
                field: 'radiation',
                // 방사선
                label: 'LBL0000288',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'excavation',
                field: 'excavation',
                // 굴착
                label: 'LBL0000277',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'highPlace',
                field: 'highPlace',
                // 고소
                label: 'LBL0000278',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'heavyEquipment',
                field: 'heavyEquipment',
                // 중장비
                label: 'LBL0000259',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
              {
                name: 'subSum',
                field: 'subSum',
                // 소계
                label: 'LBL0000016',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:8%',
              },
            ]
          },
          // {
          //   name: 'totalSum',
          //   field: 'totalSum',
          //   // 총계
          //   label: 'LBL0000018',
          //   align: 'right',
          //   sortable: true,
          //   type: 'custom',
          //   style: 'width:100px',
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.swp.workPermit.state.url + '/equip'
      this.getList();
    },
    getList() {
      if (this.searchParam.years 
        && this.searchParam.years.length > 0 
        && !this.searchParam.years[0]) {
        this.$set(this.searchParam, 'years', [])
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.$_.extend(this.searchedParam, this.searchParam)
      },);
    },
  }
};
</script>
